import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import ScheduleSection from '../components/ScheduleSection'

class MembershipPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Professional Matchmaking Membership Information"
          description={siteDescription}
        />
        <div className="membership-page page-heading text-center">
          <div className="container">
            <h1 className="text-white m-0">Professional Matchmaking</h1>
            <hr />
            <h4 className="text-white m-0">Membership Information</h4>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-md-9">
                <p className="subheading font-italic text-center pb-4">
                  Perfectly Matched offers four different membership packages
                  ranging from 10 introductions to 20 introductions.
                </p>
                <p>
                  There is a two time frame on my contract. However, if I have
                  not completed the number of matches purchased in two years I
                  will extend the client's membership for up to one additional
                  year at no additional charge, or until the agreed upon number
                  of matches has been reached - whichever comes first.
                </p>
                <p>
                  Because clients have different needs and also different
                  demands, my suggestion is to wait until after our initial
                  interview before deciding on which package best suits your
                  local dating needs in Colorado Springs.
                </p>
                <h3>Perfectly Matched Memberships include:</h3>
                <ul className="styled-list">
                  <li>
                    First and foremost Perfectly Matched conducts a proprietary
                    compatibility profile test which identifies six major
                    personality areas. Temperament, sociability, conformity,
                    affection, religion, and finance.
                  </li>
                  <li>
                    Visual and first-hand verification of driver’s license, age,
                    height, weight, and other information.
                  </li>
                  <li>
                    All clients sign an agreement allowing background checks.
                  </li>
                  <li>
                    Extreme privacy and confidentiality - information is not
                    stored on a computer.
                  </li>
                  <li>
                    Personal communication before and after each match enables
                    focus and fine tuning.
                  </li>
                  <li>
                    30 years of successful matchmaking is unmatched in Colorado.
                  </li>
                  <li>
                    You meet with me, Donna Shugrue, not an assistant trained to
                    tell you what you want to hear. In fact, I have no employees
                    so your information is totally confidential and only shared
                    with other potential qualified matches.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ScheduleSection />
      </Layout>
    )
  }
}
export default MembershipPage

export const membershipPageQuery = graphql`
  query membershipPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
